import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Flex from "app/components/Flex";
import Icon, { ChevronDown } from "app/components/Icon";
import { H5 } from "app/components/Typography";

const ViewMoreGradient = styled(Flex)`
  position: absolute;
  width: 100%;
  height: 75px;
  background: linear-gradient(
    to bottom,
    rgba(34, 34, 34, 0) 0%,
    ${({ theme }) => theme.colors.monochrome[8]} 55%
  );
  z-index: 1;
`;

const ViewMoreContainer = ({
  isOverviewExpanded,
  setIsOverviewExpanded,
  children,
  ...rest
}) => (
  <Flex
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    bg="transparent"
    display="contents"
    {...rest}
  >
    {!isOverviewExpanded && (
      <>
        <Flex
          position="relative"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          color="white"
          onClick={() => setIsOverviewExpanded(true)}
        >
          <Flex
            position="absolute"
            cursor="pointer"
            flexDirection="column"
            alignItems="center"
            mb={4}
            style={{ zIndex: 2 }}
          >
            <H5 letterSpacing="0.01em" mb={3}>
              View Program Info
            </H5>
            <Icon as={ChevronDown} />
          </Flex>
          <ViewMoreGradient />
        </Flex>
      </>
    )}
    {children}
  </Flex>
);

ViewMoreContainer.propTypes = {
  children: PropTypes.node.isRequired,
  isOverviewExpanded: PropTypes.bool.isRequired,
  setIsOverviewExpanded: PropTypes.func.isRequired,
};

export default ViewMoreContainer;
